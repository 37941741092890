import React from "react";

import "./_table.scss";

const Table = () => {
  return (
    <table>
      <thead>
        <tr>
          <th>Slapuko pavadinimas</th>
          <th>Slapuko galiojimo trukmė</th>
          <th>Slapuko aprašymas</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>_ga</td>
          <td>2 metai</td>
          <td>
            Naudoja „Google Analytics“, kad įvertintų vartotojo apsilankymo
            tikslus, parengtų ataskaitas apie svetainės aktyvumą svetainių
            operatoriams ir pagerintų vartotojų patirtį besilankant svetainėje.
          </td>
        </tr>
        <tr>
          <td>_gat</td>
          <td>1 min.</td>
          <td>
            Naudojamas „Google Analytics“ statistinei informacijai apie
            interneto svetainės lankomumą rinkti.
          </td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>24 val. </td>
          <td>
            Naudojamas „Google Analytics“ statistinei informacijai apie
            interneto svetainės lankomumą rinkti apie apsilankymo trukmę,
            naršyklę ir kt.
          </td>
        </tr>
        <tr>
          <td>gatsby-gdpr-google-analytics</td>
          <td>1 metai</td>
          <td>Vartotojo sutikimas su slapukų taisyklėmis</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table;
