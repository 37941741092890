import React from "react";
import { graphql } from "gatsby";

import { mainNavigation, homesNavigation } from "src/data";
import Header from "components/Header";
import Banner from "components/Banner";
import Layout from "components/Layout";
import Footer from "components/Footer";
import CookieBar from "components/CookieBar";
import CookieTable from "components/CookieTable";
import RichTextRenderer from "components/RichTextRenderer";

import "styles/styles.scss";

const IndexPage = ({ data }) => {
  const { logo, content } = data;
  return (
    <>
      <Header
        main={mainNavigation}
        homes={homesNavigation}
        logo={logo.childImageSharp.fluid}
        logoAlt="Moodu moduliniai namai"
      />
      {content.edges.map((item, idx) => (
        <React.Fragment key={idx}>
            <Banner
                    heading={item.node.title}
                    isInnerPage
                    imageData={item.node.image.fluid}
                    isCentered
                />
      <Layout>
      <RichTextRenderer richTextJson={item.node.privacyPolicy.json} />
        <CookieTable />
      </Layout>
      <Footer logo={logo.childImageSharp.fluid} />
      <CookieBar />
      </React.Fragment>
      ))}
    </>
  );
};

export const query = graphql`
  query Policy {
    logo: file(relativePath: { eq: "logo-top.png" }) {
      childImageSharp {
        fluid(maxWidth: 200 ) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    content: allContentfulPrivacyPolicy {
        edges {
          node {
            title
            image {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            privacyPolicy {
                json
            }
          }
        }
      }
  }
`;

export default IndexPage;
